.material-symbols-outlined {
    font-variation-settings:
    'FILL' 0,
    'wght' 500,
    'GRAD' 0,
    'opsz' 32 
  }
.Case-studies4-container {
    scroll-behavior: smooth;
  }
  
  /* @media (min-width: 1440px) {
    .Case-studies4-container {
      zoom: 1.5;
    }
  }
  
  @media (min-width: 2560px) {
    .Case-studies4-container {
      zoom: 1.7;
    }
  }
  
  @media (min-width: 3860px) {
    .Case-studies4-container {
      zoom: 2.5;
    }
  } */
  
  .Case-studies4-container ::-webkit-scrollbar {
    width: 1.3rem;
  }
  
  .Case-studies4-container ::-webkit-scrollbar-thumb {
    border-radius: 1rem;
    background: #797979;
    transition: all 0.5s ease-in-out;
  }
  
  .Case-studies4-container ::-webkit-scrollbar-thumb:hover {
    background: #222224;
  }
  
  .Case-studies4-container ::-webkit-scrollbar-track {
    background: #f9f9f9;
  }
  
.Case-studies4-container {
    padding: 30px 0px;
    margin: 0 auto;
    text-align: center;
  }
  .Case-studies3-heading {
    padding: 0 10px 50px;
    font-size: 44px;
    font-weight: 600;
    text-align: center;
  }
  
  .swiper_container {
    height: 600px;
    padding: 20px 0;
    position: absolute;
    width: 100%;
  }
  
  .swiper-slide {
    width: 480px !important;
    height: fit-content !important;
    box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
    border-radius: 30px;
    background-color: white;
    transition: all 2s ease;
  }
  /* @media (max-width: 500px) {
    .swiper_container {
      height: ;
    }
    .swiper-slide {
      width: 28rem !important;
      height: 36rem !important;
    }
    .swiper-slide img {
      width: 28rem !important;
      height: 36rem !important;
    }
  } */
  .swiper-slide img {
    width: 100%;
    height: 325px;
    border-radius: 30px 30px 0 0;
    object-fit: cover;
  }
  .swiper-slide h3{
    margin: 15px 10px 20px;
    text-align: center;
  }
  .swiper-slide ul{
    margin: 0px 20px;
    padding: 0 20px 20px 40px;
    text-align: left;
    display: none;
  }
  .swiper-slide-shadow-left,
  .swiper-slide-shadow-right {
    display: none;
  }
  .swiper-slide-active ul{
        display: block;
  }
  .swiper-slide-active h3{
    margin:8px 0;
  }
  
  .slider-controler {
    position: relative;
    bottom: 45px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
 
  
  .slider-controler .swiper-button-next {
    left: 58% !important;
    transform: translateX(-58%) !important;
  }
  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-next {
      left: 70% !important;
      transform: translateX(-70%) !important;
    }
  }
  

  
  @media (max-width: 990px) {
    .slider-controler .swiper-button-prev {
      left: 30% !important;
      transform: translateX(-30%) !important;
    }
  }
  
  @media (max-width: 450px) {
    .slider-controler .swiper-button-prev {
      left: 20% !important;
      transform: translateX(-20%) !important;
      
    }
    .swiper-slide {
      display: block;
      width: 97% !important;
}
  }
  
  .slider-controler .slider-arrow {
    background: var(--white);
    width: 3.5rem;
    height: 3.5rem;
    border-radius: 50%;
    left: 42%;
    transform: translateX(-42%);
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .slider-controler .slider-arrow ion-icon {
    font-size: 10px;
    color: #222224;
  }
  
  .slider-controler .slider-arrow::after {
    content: '';
  }
  
  .swiper-pagination {
    margin: 0 !important;
    bottom: -15px !important;
  }
  
  .swiper-pagination .swiper-pagination-bullet {
    filter: drop-shadow(0px 8px 24px rgba(18, 28, 53, 0.1));
  }
  
  .swiper-pagination .swiper-pagination-bullet-active {
    background:#6f8eef;
  }







  .case-studies3-container {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    padding: 20px 0;
    background-color: transparent;
    margin-top: 5%;
    border-radius: 8px;
    overflow: hidden;
    gap: 20px;
  }
 
  .case-studies3-heading {
    padding: 12px 20% 10px;
    margin-bottom: 40px;
    width: 100%;
    /* background-color: #e9f0fa; */
    /* border-radius: 40px; */
    /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
  }
 
  .case-studies3-heading h1 {
    color: black;
    text-align: center;
    font-size: 44px;
    margin-bottom: 15px;
    font-weight: 600;
  }
 
  .case-studies3-heading p {
    color: #666;
    line-height: 1.4;
    text-align: center;
  }
 
 
  .case-studies3-carousel {
    width: 200%;
    background-color: transparent;
    position: relative;
  }
 
  .case-studies-slider {
    transition: transform 0.5s ease, box-shadow 0.5s ease;
    background-color: transparent;
    border-radius: 12px;
    max-width: 270px;
    /* transform: rotate(7deg);
    transition: transform 0.5s ease-in-out; */
  }
 
  .adv3 {
    background-color: #e9f0fa;
    height: 340px;
    margin: 6px;
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
    gap: 5px;
    transition: transform 0.5s ease-in-out;
    box-shadow: rgba(0, 0, 0, 0.15) 1.95px 1.95px 2.6px;
    opacity: 0.6;
  }
  .adv3-content{
    display: flex;
    flex-direction: column;
    padding: 30px 35px;
    width: 45%;
    align-items: center;
    justify-content: center;
  }
  .adv3-images {
    height: 100%;
    width: 750px;
  }
 
  .slick-center .adv3 {
    color: var(--blue5);
    /* border: 3px solid transparent; */
    transform: scale(1.05);
    transition: transform 0.5s ease-in-out;
    background-color: #e9f0fa;
    /* border: 4px solid rgb(55, 90, 194); */
    /* box-shadow: .75rem .75rem .2rem #4c77e336; */
 
    opacity: 1;
  }
 
  .slick-center .case-studies3-slider {
    transition: transform 0.5s ease-in-out;
  }
 
  .adv3 ul li {
    margin: 0px 10px 10px;
  }
 
  .adv3 h3 {
    color: orangered;
    font-size: 24px;
    margin: 0 0 20px;
  }
 
  .adv3 li {
    color: var(--text-grey);
    ;
    width: 100%;
    font-weight: 400;
    font-size: 15px;
    top: 0%;
 
  }
 
 
  .center .slick-slide {
    padding: 45px 20px;
    transition: transform 0.5s ease;
    opacity: 1;
  }
 
  .center .slick-slide .non-centered-left {
    transform: rotateX(-30deg);
  }
 
  .center .slick-slide.non-centered-right {
    transform: rotateX(30deg);
  }
 
  .center .slick-center {
    transform: scale(1);
    opacity: 1;
  }
 
  .slick-prev,
  .slick-next {
    display: none !important;
  }




  @media (max-width: 450px) {
    .slider-controler .swiper-button-next {
      left: 80% !important;
      transform: translateX(-80%) !important;
    }
    .case-studies3-heading p{
      width: 90%;
      font-size: 15px;
    }
    .case-studies3-heading h1{
      width: 90%;
      font-size: 45px;
    }
    .case-studies3-heading {
      padding: 10px 5% 0px;
      margin-bottom: 40px;
      width: 100%;
      /* background-color: #e9f0fa; */
      /* border-radius: 40px; */
      /* box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px 0px, rgba(60, 64, 67, 0.15) 0px 2px 6px 2px; */
    }
  }