* {
  margin: 0;
  padding: 0;
  --background1: radial-gradient(circle at center, #56CCF2, #2F80ED);
  box-sizing: border-box;
  --blackblue: #0b253c;
  --blue1: #45b6fe;
  --blue2: #3792cb;
  --blue3: #1b7ced;
  --blue4: #296d98;
  --blue5: #1c4966;
  --blue6: #333;
  --navy1: #23395d;
  --navy2: #203354;
  --navy3: #1c2e4a;
  --navy4: #192841;
  --navy5: #152238;
  --body-background: #1d2951;
  --background-lgtblue: #39ccef;
  --background-lgtblue1: #D1EAF0;
  --background-darkblue: #5c39ef;
  --background-darkblue1: #5356FF;
  --border-gradient: linear-gradient(142.48deg, #2340d5 2.46%, #2340d5 2.47%, #c82e53 52.9%, #7f2fcf 102.3%);
}

body {
  font-family: "Poppins", sans-serif;


}

.App {
  width: 100%;
  background-color: #ffffff;
overflow: hidden;
gap: 50px;

}

.headings {
  margin: 20px 0;
  font-size: 44px;
  font-weight: 600;
}

.home {
  height: 400px;
  width: 100%;
}

.nvision {
  color: orangered;
  font-style: italic;
}








@media (max-width:1100px) {
  .input {
    width: 400px;
  }

  .result-image {
    width: 350px;
  }

  .sample {
    width: 250px;
  }

  .description {
    margin-left: 5px;
    font-size: 25px;
    line-height: 1.5;
  }

  .video-container {
    width: 500px;
  }
}


@media only screen and (max-width: 800px) {
  .app {
    max-width: 99%;

  }

  .App {
    width: 100%;
  }

  .result-container1 {
    height: 800px;
    flex-direction: column;
    width: 700px;
    overflow: hidden;
  }

  .details {
    max-width: 100%;
  }

  .hr-line1 {
    display: block;
  }

  .vertical-line {
    display: none;
  }

  .result {
    flex-direction: column;
  }

  .output-content {
    flex-direction: column;

  }

  .output-container {
    flex-direction: column;
    height: 800px;

  }

  .result-out {
    height: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .step {
    width: 50%;
  }

  .head {
    flex-direction: column;
    align-items: center;
    gap: 1rem;
    padding: 1em;
  }

  .description {
    /* padding: 20px; */
    line-height: 25px;
    font-size: 20px;
    text-align: center;
    margin-left: 0%;
    margin-right: 0%;
  }

  .sample {
    width: 300px;
    height: 171px;
    margin: 5px;
  }

  button {
    padding: 8px;
  }

  video {
    object-fit: fit;
    width: 95%;
    /* margin: 10px; */
    border-radius: 20px;
  }

  .result-image {
    width: 380px;
    max-height: 300px;
    object-fit: cover;
    border-radius: 5px;
    margin-top: 10px;
  }

  .paragraph {
    font-size: 8px;
  }

  .output-buttons button {
    padding: 5px;
    cursor: pointer;
    border: none;
    background-color: #f0f0f0;
    color: #333;
    font-size: 11px;

    width: 135px;
    transition: background-color 0.3s;
  }

  .advantages-container h2 {
    text-align: center;

  }

  .slider-container {
    padding: 0px;
    width: 60%;
  }

  .slick-prev,
  .slick-next {
    display: block !important;

  }

  .center .slick-prev {
    left: -100px;
  }

  .center .slick-next {
    right: -100px;
  }

  .main-container {
    height: 100%;
    overflow: auto;

  }

  /* .steps1{
    justify-content: flex-start;
  } */
  .background-container {
    padding-left: 0px;
    gap: 0px;
    width: 30%;
    text-align: center;
  }

  .process-image {
    width: 200px;
    margin-right: 40px;

  }

  .process-step {
    padding: 0%;
    height: 300px;
  }

  .content-right {
    width: 70%;
    /* justify-content: space-evenly; */
  }

  .steps1 {
    margin: 0 10px 0 50px;
  }

  .criteria p {
    color: black;
    font-size: 18px;
    font-weight: 600;
  }

  .check-icon {
    width: 40px;
  }

  .description2 {
    font-size: 14px;
  }

  .step-title {
    font-size: 25px;
  }


  p {
    font-size: 10px;
  }

  .company-logo {
    width: 130px;
  }

  .adv1 {
    width: 45%;
    margin: 10px;
  }

  .adv-img {
    width: 100%;
  }

  .output-image {
    width: 350px;
  }

  .result-image {
    width: 350px;
  }

  .input {
    height: 350px;
    width: 400px;
  }

  .video-container {
    position: relative;
    background: transparent;
    max-width: 700px;
    overflow: hidden;
    border-radius: 20px;
  }

  .video-container {
    margin: 2px;
  }

  .description {
    line-height: 30px;
  }

  .test-heading {
    font-size: 30px;
  }

  .overcome {
    font-size: 20px;
  }

  .header h1 {
    font-size: 30px;
    line-height: 30px;
  }

  .test-heading-container p {
    font-size: 15px;
  }

  .steps {
    width: 100%;
  }

  .step {
    width: 40%;
    padding: 0;
  }

  .step h3 {
    font-size: 16px;
  }

  .test-heading-container1 h2 {
    font-size: 30px;
    margin: 0px;
  }
}


@media (max-width:500px) {
  .carousel-caption {
    text-align: center;
  }

  .slider-container {
    padding: 0px;
    width: 80%;
  }

  .output-buttons button {
    width: 120px;
  }

  .advantages-container h2 {
    padding: 0px;
    margin-bottom: 0px;
    margin: 0px;

  }

  .slick-prev,
  .slick-next {
    display: block !important;

  }

  .center .slick-prev {
    left: -30px;
  }

  .center .slick-next {
    right: -45px;
  }

  .adv1 h3 {
    font-size: 25px;
  }

  .adv1,
  .slick-center {
    height: 400px;
  }

  .adv1 p {
    font-size: 15px;
  }

  .adv-images {
    height: 140px;
    width: 90%;
  }

  .output-image {
    width: 300px;
  }

  .App {
    width: 100%;
  }

  .adv {
    flex-wrap: wrap;

  }

  .adv1 {
    width: 100%;
  }

  .adv-img {
    width: 95%;
  }

  .result-image {
    width: 290px;
    height: 250px;

  }

  .input {
    width: 300px;
  }

  .video-container {
    position: relative;
    background: transparent;
    max-width: 98%;

    display: flex;
    justify-content: center;
    overflow: hidden;
    border-radius: 20px;
    margin: 0px;
  }

  .head {
    flex-direction: column;
    align-items: center;
    gap: 0.1rem;
    padding: 0.3em;
  }

  .output-container {
    background-color: rgba(211, 211, 211, 0.7);
    border: 1px solid #e0e0e0;
    border-radius: 10px;
    padding: 20px;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
    display: flex;

    width: 350px;
    height: 710px;
    flex-direction: column;
    flex-grow: 0 0 1;
  }

  .result-description {
    font-size: 15px;
    margin-top: -90px;
  }

  .result-container {
    padding: 10px;
  }

  .result-container1 {
    width: 320px;
    height: 710px;
    overflow: hidden;
  }

  .result-container1 h3 {
    text-align: center;
  }

  .sample {
    width: 230px;
    height: 130px;
    margin: 5px;
  }

  .details {
    padding: 0px;
  }

  .head {
    gap: 0rem;
  }

  .step {
    width: 100%;
  }

  h2 {
    font-size: 20px;
  }

  .video-head {
    text-align: center;
  }

  .test-heading {
    font-size: 40px;
  }

  .description {
    line-height: 25px;
    font-size: 15px;
    padding: 0px;
    margin: 0px;
  }

  .steps1 {
    flex-direction: column;
    width: 100%;
    height: auto;
  }

  .background-container {
    width: 100%;
    display: flex;
    justify-content: center;
    margin: 0%;
    margin-right: 0px;

  }

  .main-container {
    padding: 0%;
    margin: 0%;

  }

  .content-right {
    width: 90%;
    margin: 0px;
    padding: 0%;
  }

  .process-image {
    margin-right: 0px;
    height: 200px;
    margin-bottom: 25px;
  }

  .steps1 {
    margin: 0px;
    margin-bottom: 10%;
  }

  .test-heading {
    font-size: 20px;
    width: 100%;
  }

  .step {
    margin: 10px;
    height: 100%;
  }

  .test-heading-container p {
    font-size: 13px;
  }

  .header h1 {
    font-size: 20px;
  }

  .overcome {
    font-size: 13px;
  }

  .process-step {
    height: auto;
  }
}


@media (max-width:400px) {
  .output-container {
    width: 98%;
  }

  .result-container1 {
    width: 100%;
    margin: 0px;
  }

  .result-container {
    padding: 0px;
  }

  .result-image {
    width: 240px;
  }

  .sample-image {
    width: 200px;
    height: 100px;
  }

  .result-description {
    font-size: 10px;
  }

  .adv1 h3 {
    font-size: 15px;
  }

  .adv1 p {
    font-size: 10px;
  }

  .adv1 .slick-center {
    height: 300px;
  }

  .slick-prev {
    margin-left: 20px;
  }

  .slick-next {
    margin-right: 20px;
  }
}

