.carousel-slide {
    position: relative;
  }
  
  .carousel-image-container {
    width: 100%;
  
    overflow: hidden;
  
  }
  
  .carou {
    height: 900px;
  }
  

  
  
  .description {
    line-height: 50px;
    text-align: center;
    align-items: center;
    font-weight: 500;
    color: black;
    font-size: 28px;
    width: 80%;
  
  }
  
  .heading {
    font-weight: 700;
    color: orangered;
    font-size: 70px;
    margin-bottom: 10px;
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    margin-top: 10%;
  
  }
  
  .text {
    width: 100%;
    padding: 30px;
    
    text-align: center;
    height: 380px;
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: center;
    text-align: center;
    background: transparent;
  
  }
  
  .contact {
    background-color: #5cbe2d;
    color: white;
    height: 50px;
    font-size: 20px;
  }
  
  .carousel-slide {
    position: relative;
  
  }
  
  
  .carousel-image-container {
    height: 550px;
  }
  
  .carou {
    background: url("../Untitled_design\ \(3\).png");
    background-size: contain;
    background-repeat: no-repeat;
    object-fit: cover;

   
  
  }
  
  .carousel-image-container img {
    width: 100%;
    height: 550px;
    
  }
  
  
  
  .description {
    line-height: 50px;
    text-align: center;
    align-items: center;
    font-weight: 500;
    color: black;
    font-size: 28px;
    width: 80%;
  
  }

  .apps1{
    width: 100%;
    height: 100%;
  }
  .home {
    height: 400px;
    width: 100%;
  }




  @media (min-width:501px) and  (max-width:1100px){
    .carou{
    
      background-size: cover;
      
      object-fit: contain;
      background-position: center;
      
    }
    .description{
      font-size: 25px;
      line-height: 30px;
      width: 98%;
    }
    .heading{
      font-size: 50px;
    }
    .contact{
      font-size: 20px;
      height: 50px;
    }
    .carousel-image-container img {
      width: 100%;
      height: 400px;
      object-fit: fill;
    }
    .carousel-image-container {
      height: 400px;
    }
    .carou {
      height: 800px;
    }
    .text{
      height: 400px;
    }
    
  }

  @media  (max-width:500px){
    .carou{
      background-size: cover;
      
      background-position: center;
      
    }

    .description{
      font-size: 1.1rem;
      line-height: 30px;
      width: 99%;
    }

    .contact{
      margin-top: 15px;
    }

    .heading{
      font-size: 50px;  
    }

    .contact{
      font-size: 20px;
      height: 50px;
    }

    .carousel-image-container img {
      width: 100%;
      height: 400px;
      object-fit: fill;
    }

    .carousel-image-container {
      height: 400px;
    }

    .carou {
      height: 900px;  
    }

    .text{
      height: 450px;  
    }
    
  }