.navigation {

  padding:auto;
  z-index: 2;
  vertical-align: middle;
  
  height: 80px;
  width: 98%;
  display: flex;
  justify-content: center;
  align-items: center;
  position: fixed;
  margin: 15px;
  margin-top: 10px;
  border-radius: 20px;
  background-color: transparent;
  color: black;
  transition: background-color 0.3s ease-in-out, color 0.3s ease-in-out;
  align-self: center  ;
  box-sizing: border-box;


 
}
.navigation.scrolled{
  background-color: #DCE7F6;
}



.logo {
  width: 70px;
  margin: 10px;
  margin-left: 35px;
  height: 60px;
  margin-top: 15px;
}
.brand-name {
  text-decoration: none;
  color: black;
 
  display: flex;
  justify-content: center;
  align-items: center;
  
}
.logo-head{
  font-size: 25px;
  color: #333;
  font-weight: bold;
}
.brand-name1 {
  text-decoration: none;
  color: black;

  
}
.navigation-menu {
  width: 70%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  

}
.navigation-menu ul {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
  padding: 0;
  margin-right: 20px;
}
.navigation-menu li {
  
  list-style-type: none;
  margin: 0 0.5rem;
  font-weight: 600;
  font-size: 16px;
}
.navigation-menu ul li a:hover {
  color: orangered; /* Change text color on hover */
}
.navigation-menu li a {
  text-decoration: none;
  width: 100%;
  color: black;
}
.submenu-arrow{
  vertical-align: middle;
  font-size: 15px;
}
.hamburger {
  border: 0;
  margin: 0;
  height: 50px;
  width: 50px;
  padding: 0.5rem;
  border-radius: 50%;
  background-color: orangered;
  cursor: pointer;
  transition: background-color 0.2s ease-in-out;
  position: absolute;
  top: 50%;
  right: 25px;
  transform: translateY(-50%);
  display: none;
}
.buttonnav {
  background-color: orangered; 
  color: #ffffff; 
  padding: 10px 20px; 
  border-radius: 30px;
  height: 50px;
  font-weight: bold;
  margin: auto;
  margin-right: 20px;
  font-size: 20px;
  
  
}

.hamburger:hover {
  background-color: orangered;
}
@media screen and (max-width: 768px) {
  .hamburger {
    display: block;
  }
  .navigation-menu{
    width: 60%;
    
  }
 
  .hamburger.onClick + .navigation {
    background-color: #DCE7F6;
  }
  .logo {
    width: 100px;
    margin: 10px;
    margin-left: 20px;
    height: 60px;
    
  }
  .logo-head{
    font-size: 27px;
  }
  .navigation{
    padding: 0;
    padding-top: 10px;
    width: 96%;
  }
  .navigation-menu ul {
    display: none;
  }
  .navigation-menu ul {
    position: absolute;
    top: 55px;
    z-index: -1;
    left: 0;
    flex-direction: column;
    width: 100%;
    height: auto;
    background-color: #DCE7F6;
    
    
  }
  .navigation-menu li {
    text-align: left;
    margin-top: 30px;
    margin-left: 30px;
  }
  .navigation-menu li a {
    color: black;
    width: 100%;
    padding: 1.5rem 0;
  }
  
  
  .navigation-menu.expanded ul {
    display: block;
    height: 350px;
    border-radius: 20px;
  }
  .buttonnav{
    display: none;
  }
}



@media (max-width:500px){
  .navigation-menu{
    width: 60%;
    
  }
  .hamburger.onClick + .navigation {
    background-color: #e9f0fa;
  }
  .logo {
    width: 80px;
    margin: 20px;
    margin-left: 0px;
    height: 60px;
    
  }
  .logo-head{
    font-size: 27px;
    display: none;
  }
  .navigation{
    padding: 0;
    padding-top: 10px;
    width: 94%;
  }
}
