:root {
  --clr-1: #052b2f;
  --clr-2: #073438;
  --clr-3: #0e4b50;
  --clr-4: #2d8f85;
  --clr-5: #637c54;
}
 



.feature-cont1 {
  background-color: transparent; 
  
  border-radius: 8px; 
  overflow: hidden;
}

.feature-head1 {
  font-weight: 600;
  color: #2c3e50;
  font-size: 40px;
  margin-bottom: 0px;
  text-align: center;
}

.feature-container1 {
  display: flex;
 position: relative;
  height: 850px;
  justify-content: flex-start;
  align-items: center;
  overflow: hidden;
}
.connection-lines {
  z-index: 0; 
}




.left-side {
  width: 58%;
  height: 770px;
  border-top-right-radius: 50%;
  border-bottom-right-radius: 50%;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  position: relative;
  left: -5%;
  padding: 20px;
  background-color: #6f8eef; 
  box-shadow: 0 4px 20px rgba(0, 0, 0, 0.25); 
}

.center-image {
  width:700px;
  height: 680px;
  overflow: hidden;
  border-radius: 50%;
  border: 8px solid orangered; 
  background: linear-gradient(135deg, #ffffff 0%, #ecf0f1 100%); 
  transition: transform 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 25px;
  box-shadow: 0 10px 20px rgba(44, 62, 80, 0.15); 
}

.center-feature-image {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease-in-out;
}

.center-feature-image {
  margin-top: 2%;
  margin-left: 2%;
  width: 750px;
  height: 680px;
  transition: transform 0.3s ease-in-out;
  object-fit:fill;
}

.central-topic {
  position: absolute;
  color: white;
  font-weight: bold;
  text-align: center;
  width: 100%;
}

.features-list {
  position: absolute;
  width: 100%;
  left: 55%;
  top: 50%;
  transform: translateY(-50%);
}


.feature-item {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 40px;
  background-color: #e9f0fa;
  color: black;
  width: 550px;
  height: 110px;
  box-shadow: .50rem .75rem .2rem #c9ccd0;
  padding-left: 40px;
  border:1px solid rgba(255,255,255,.9);
}

.feature-item h2 {
  margin-bottom: 5px;
  font-size: 20px;
  color: orangered;
  font-weight: 600;
}

.feature-number {
  position: absolute;
  top: -10%; 
  left: -20px;
  background-color: orangered;
  color: white;
  height: 40px;
  width: 40px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  box-shadow: 0 2px 5px rgba(0,0,0,0.2);
  z-index: 3;
}

.feature-item p {
  font-size: 12px;
  text-align: start;
  font-weight: 500;
}


.feature-item::before {
  content: '';
  position: absolute;
  width: 300px; 
  height: 3px; 
  background: linear-gradient(to right, transparent 68%, #007BFF 50%, transparent 75%);
  background-size: 300% 100%;
  animation: moveColor 2s linear both;
  left: 50%;
  top: 50%;
  transform-origin: left;
  
}

@keyframes moveColor {
  0% {  background-position: 0% center; }
  100% {  background-position: 100% center; }
}

.feature-item-1 {
  transform: rotate(0deg) translateX(-100px) translateY(-15px);
}
.feature-item-2 {
  transform: rotate(0deg) translateX(20px) translateY(-10px);
}

.feature-item-3 {
  transform: rotate(0deg) translateX(80px) translateY(10px);
}

.feature-item-4 {
  transform: rotate(0deg) translateX(25px) translateY(30px);
}

.feature-item-5 {
  transform: rotate(0deg) translateX(-100px)  translateY(40px);
}

.feature-item::before {
  content: '';
  position: absolute;
  width: 300px;
  height: 0px;
  background-color: #e9f0fa;
  left: 50%;
  top: 50%;
  transform-origin: left;
  z-index: 1; 
}


:root {
  --total-animation-time: 10s; 
}

.feature-item-1::before {
  transform: translate(-100%, -50%) rotate(-197deg);
  width: 302px;
  animation-delay: 0s; 
}


.feature-item-2::before {
  transform: translate(-100%, -50%) rotate(-190deg);
  width: 293px;
  animation-delay: 2s;
}


.feature-item-3::before {
  transform: translate(-100%, -50%) rotate(-180deg);
  width: 327px;
  animation-delay: 4s, var(--total-animation-time); 
}


.feature-item-4::before{
  transform: translate(-100%, -50%) rotate(-170deg);
  width: 290px;
  animation-delay: 6s, var(--total-animation-time); 
  
}

.feature-item-5::before {
  transform: translate(-100%, -50%) rotate(-167deg);
  width: 303px;
  animation-delay: 8s, var(--total-animation-time); 
}


.feature-item.highlight {
  background-color: #6f8eef; 
  color: white;
  transition: background-color 0.4s ease-in-out;
  border: 2px solid ;
  box-shadow: .50rem .75rem .2rem #4c77e336;
}
.feature-item.highlight h2 {
  color: white;
}

.feature-item.highlight p {
  font-weight: 500;
  color: white;
}

.feature-item-1.highlight {
  animation: highlightFeature 2s linear both;
  animation-delay: 0s;
}

.feature-item-2.highlight {
  animation: highlightFeature 2s linear both;
  animation-delay: 2s;
}

.feature-item-3.highlight {
  animation: highlightFeature 2s linear both;
  animation-delay: 4s;
}

.feature-item-4.highlight {
  animation: highlightFeature 2s linear both;
  animation-delay: 6s;
}

.feature-item-5.highlight {
  animation: highlightFeature 2s linear both;
  animation-delay: 8s;
}



@media (max-width:1300px){
  .features-list {
    position: absolute;
    width: 100%;
    left: 54%;
    top: 50%;
    transform: translateY(-50%);
  }
  .feature-item{
    width: 490px;
  }
  .left-side{
    height: 650px;
  }
  .center-image{
    height: 600px;
    width: 85%;
  }
  .center-feature-image{
    height: 570px;
  }
  .feature-item h2{
    font-size: 16px;
  }
  .feature-item p{
    font-size: 11px;
  }
}
































































:root {
  --size-default: 22px;
  --size-sm: 16px;
  --color-green: orangered;
  --color-black: #000;
}

@property --angle {
  syntax: "<angle>";
  initial-value: 0deg;
  inherits: false;
}



@keyframes rotate {
  0% {
      --angle: 0deg;
      --glowColor: var(--color-green);
  }

  100% {
      --angle: 360deg;
      --glowColor: var(--color-green);
  }
}


.center-image{
  border: 0px solid transparent;
  padding: 0px;
}


.center-image1 {
  display: flex;
  align-items: center;
  justify-content: center;
  
  border: none;
  border-radius: 50%;
  background: none;
  position: relative;
  border: 0px solid white;
  text-decoration: none;
  transition: all 0.18s ease-in-out;
  transform: translate3d(0, 0, 0);
}

.center-image1:after,
.center-image1:before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: calc(100px + 1.5px);
  border-radius: 50%;
  background-size: 100% 100%;
  background-position: 0px 0px;
  background-image: conic-gradient(from var(--angle) at 50% 50%, rgba(0, 0, 0, 0) 0%, rgba(0, 0, 0, 0) 33%, var(--glowColor) 50%, rgba(0, 0, 0, 0) 66%, rgba(0, 0, 0, 0) 100%);
  animation: rotate 2s infinite linear;
  
}



.center-image .center-feature-image {
  width: 685px;
  z-index: 1; 
  margin: 50px;
  border-radius: 50%;
  background-color: white;
  height: 665px;
}














@media (max-width:1200px){
  .features-list {
    position: absolute;
    width: 100%;
    left: 50%;
    top: 50%;
    transform: translateY(-50%);
  }
  .feature-item{
    width: 470px;
  }
  .left-side{
    height: 650px;
  }
  .center-image{
    height: 590px;
    width: 91%;
    margin-right: 8px;
    padding: 0px;
  }
  .center-image1{
    height: 588px;
    width: 100%;
    margin: px;
  }
  .center-image .center-feature-image {
    width: 97%;
    z-index: 1; 
    margin: 0px;
    padding-left: 0px;
    border-radius: 50%;
    background-color: white;
    height: 578px;
  }
  .center-feature-image{
    height: 340px;
    width: 100%;
  }

  
}




@media (max-width:1000px){
  .feature-container1{
    flex-direction: column;
    height: 1500px;
  gap: 50px;
  }

  .left-side{
    width: 100%;
    height: 700px;
    margin-top: 30px;
    justify-content: flex-end;
    
  }
  .center-image{
    height: 650px;
    width: 91%;
    margin-right: 8px;
    padding: 0px;
  }
  .center-image1{
    height: 648px;
    width: 100%;
    margin: px;
  }
  .center-image .center-feature-image {
    width: 97%;
    z-index: 1; 
    margin: 0px;
    padding-left: 0px;
    border-radius: 50%;
    background-color: white;
    height: 638px;
  }
  .center-feature-image{
    height: 340px;
    width: 100%;
  }
  .feature-item-1 {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
  .feature-item-2 {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
  .feature-item-3 {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
  .feature-item-4 {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
  .feature-item-5 {
    transform: rotate(0deg) translateX(0px) translateY(0px);
  }
  .features-list {
    position:relative;
    width: 100%;
    left: 0%;
    top: 0%;
    
    transform: translateY(0%);
  }
  .feature-cont1{
    height: 1500px;
  }
  .feature-item{
    width: 90%;
    margin: auto;
    margin-bottom: 20px;
  }
  .feature-item h2{
    font-size: 15px;
  }
  .feature-item p{
    font-size: 10px;
  }
}


@media (max-width:500px){
  .feature-container1{
    flex-direction: column;
    height: 1200px;
  gap: 50px;
  }

  .left-side{
    width: 100%;
    height: 400px;
    margin-top: 30px;
    justify-content: flex-end;
    
  }
  .center-image{
    height: 350px;
    width: 91%;
    margin-right: 8px;
    padding: 0px;
  }
  .center-image1{
    height: 348px;
    width: 100%;
    margin: px;
  }
  .center-image .center-feature-image {
    width: 97%;
    z-index: 1; 
    margin: 0px;
    padding-left: 0px;
    border-radius: 50%;
    background-color: white;
    height: 338px;
  }
  .center-feature-image{
    height: 340px;
    width: 100%;
  }
  .feature-cont1{
    height: 1200px;
  }
  .feature-item p{
    font-size: clamp(9px, 2vw, 22px);
  }
  .feature-item h2{
    font-size: clamp(14px, 2vw, 22px);
  }
  .feature-item{
    padding: 15px;
  }
  
}











