.advantages-container {
    padding: 2em ;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    align-self: center;
    color: #333;
    flex-grow: 0 0 1;
    gap: 50px;
    overflow: auto;
    margin-bottom: 30px;
    overflow: hidden;
  }
  
  .adv {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    align-self: center;
    flex-wrap: wrap;
    width: 100%;
  
  }
  
  .advantages-container h2 {
    text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.1);
    font-weight: 600;
    color: black;
    font-size: 45px;
  }
  
  .slider-container {
    display: flex;
  
  }

.adv1 {
    opacity: 1;
    
   background-color: #f8cbd21a;
   border: 1px solid #80D6EC;
    border-radius: 40px;
    width: 320px;
    height: 310px;
    margin: 20px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    box-shadow: .75rem .75rem .2rem #4c77e336;
    justify-content: center;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .adv1:hover{
    transform: scale(1.05);
  }
  
  
  
  .adv-images2{
    width: 300px;
    height: 200px;
    border-radius: 20px;
  }
  
  .adv-img{
   padding: 0px;
    width: 100%;
    
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  
  
  
  .adv1 h3{
    
    font-size: 20px;
    text-align: center;
    color: orangered;
    
    
  }
  .adv1 p {
   color: white;
    width: 90%;
    color: #333;
    text-align: center;
    font-weight: 400;
    font-size: 15px;
    margin-top: 10px;
    top: 0%;
    margin-bottom: 10px;
  }   
















  @media (max-width:450px){
    .slider-container{
      flex-direction: column;
      justify-content: center;
      align-items: center;
    }
    .advantages-container h2{
      text-align: center;
    }
  }


  @media (max-width:800px){
    .slider-container{
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
    .adv1{
      width: 310px;
    }
    .adv-images2{
      width: 290px;
    }
  }

  @media (max-width:1200px){
    .slider-container{
      flex-direction: row;
      justify-content: center;
      align-items: center;
      flex-wrap: wrap;
      width: 100%;
    }
    .adv1{
      width: 310px;
    }
    .adv-images2{
      width: 290px;
    }
  }
























  