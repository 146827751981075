.feature-container {
  padding: 40px;
  text-align: center;
}
 
.feature-head {
  font-size: 44px;
  color: #333;
  
  margin: 40px;
  margin-top: 0px;
  font-weight: 600;
  margin-bottom: 70px;
}



.features-grid {
  display: flex;
  
  justify-content: space-around;
  flex-wrap: wrap; 
  gap: 40px; 
  padding: 20px;
 
}
.features-grid > div:hover  img {
  width: 100%;
  height: 100%;
}
.feature-icon img {
  width: 100%;
  height: 250px; 
  object-fit: cover;
  filter: brightness(115%);
}
.feature-tile-even {
  background-color: white;
  border: 0px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-top: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 320px;
  
  box-shadow: .75rem .75rem .2rem #4c77e336;
  border: 1px solid #80D6EC;
}

.feature-tile-odd {
  background-color: white;
  border: 0px solid #ddd;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
  margin-bottom: 70px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: column;
  max-width: 320px;
  box-shadow: .75rem .75rem .2rem #4c77e336;
  border: 1px solid #80D6EC;
}


 
.feature-icon img {
  width: 100%;
  height: 250px; 
  object-fit: cover;
  filter: brightness(115%);
}

 
.feature-info {
  padding: 20px;
 
 
}
 
.feature-info h2 {
  font-size: 18px;
  color: #333;
  margin-bottom: 10px;
}
 
.feature-info p {
  font-size: 13px;
  color: #666;
  width: 90%;
  margin: auto;
  text-align: center;
}

.feature-tile-even, .feature-tile-odd {
  background-color: white;
  border: 1px solid #80D6EC;
  border-radius: 8px;
  overflow: hidden;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  transition: all 0.3s ease;
  box-shadow: 0.75rem 0.75rem 0.2rem #4c77e336;
  flex: 1 1 250px; /* Flex basis of 250px with the ability to grow and shrink */
  max-width: 320px; /* Max width as previously defined */
}

.features-grid > div:hover  img {
  width: 100%;
  height: 100%;
}

.features-grid > div:not(:hover) {
  transform: scale(1); /* This scales down non-hovered items */
  opacity: 1;
  height: 350px; /* Optionally reduces the opacity of non-hovered items */
}

.features-grid > div {
  transition: all 0.3s ease-in-out; /* Ensures smooth transformation */
}

.features-grid > div:hover {
  transform: scale(1.03) translateY(-20px);
  z-index: 1;
  max-width: 450px;
  flex: 1 1 450px;
}


@media(min-width:501px) and (max-width: 1000px){
  .features-grid {
    justify-content: space-between; 
    
    
  }
  .feature-tile-even, .feature-tile-odd {
    
    margin: 5px; 
    flex: 1 1 200px; 
  }
  
  .feature-tile-even{
    margin:0 auto auto;
  }
  .feature-tile-odd{
    margin: 30px auto auto;
  }
  .features-grid > div:hover {
    max-width: 250px;
    flex: 1 1 250px;
    transform: scale(1.00) translateY(0px); 
    z-index: 1; 
    
  height: 350px;
  }
  .features-grid > div:hover > .feature-icon img{
    height: 250px;
    
  }
}
@media(max-width: 500px){


  .features-grid {
    justify-content: center; 
  }
  .feature-tile-even, .feature-tile-odd {
    flex: 1 1 50%; 
    margin: 10px 0; 
  }
  
  .feature-tile-even{
    margin: auto;
  }
  .feature-tile-odd{
    margin: auto;
  }
  .features-grid > div:hover {
    
    transform: scale(1.00) translateY(0px); 
    z-index: 1; 
   
  
  height: 350px;
  }
  .features-grid > div:hover > .feature-icon img{
    height: 250px;
    
  }
  .feature-head {
    font-size: 44px;
    color: #333;
    
    margin: 0px;
    margin-top: 0px;
    font-weight: 600;
    margin-bottom: 20px;
  }
}

