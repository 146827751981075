.video-container {
    position: relative;
    background-image: url("../vector_design.jpg");
    display: flex;
    justify-content: center;
    padding: 20px;
    padding-bottom: 40px;
    overflow: hidden;
    border-radius: 20px;
    background-size: cover;
    background-repeat: no-repeat;
    
}

video {
    width: 950px;

    object-fit: cover;
    border-radius: 20px;
    align-self: center;

}

.video-controls {
    position: absolute;
    top: 50%;
    right: 50%;
    transform: translate(50%, -50%);
    display: flex;
    justify-content: center;
    align-items: center;
    background: orangered;
    width: 50px;
    height: 50px;
    border-radius: 50%;
    transition: opacity 0.3s;
    opacity: 1;
}



.control-icon1 {
    font-size: 35px;
    cursor: pointer;
    color: black;
    margin: auto;
}




@media (min-width:501px) and (max-width:800px){
    .video-container{
        width: 100%;
        padding: 10px;
    }

    video{
        width: 99%;
    }
}


@media (min-width:801px) and (max-width:1200px){
    .video-container{
        width: 100%;
        padding: 10px;
    }

    video{
        width: 80%;
    }
}
@media (max-width:500px){
    .video-container{
        width: 100%;
        padding: 10px;
        
    }

    video{
        width: 99.8%;
    }
}