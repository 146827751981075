.video-head {
    color: var(--blue6);
    margin-top: 30px;
    font-size: 45px;
    font-weight: 600;
    margin-bottom: 40px;
  }
  
  .test-heading-container {
    width: 100%;
    padding: 20px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    overflow: hidden;
    text-align: center;
  }
  
  .test-heading-container1 h2 {
    color: black;
    border-radius: 10px;
  
    padding: 20px;
  
  }
  .pa{
    font-weight: bold;
  }
  
  .test-heading-container p {
    color: black;
    font-weight: 550;
    font-size: 20px;
  }
.demo-container {
    background-color: transparent;
    border-radius: 10px;
    padding: 10px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    flex-grow: 0 0 1;
}

.app {
    /* background-color: rgba(211, 211, 211); */
    background-color: transparent;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    padding: 20px;
    max-width: 99%;
    width: 100%;
    flex-grow: 0 0 1;
    /* margin-top: 30px; */
}
.demo {
    color: #FF9843;
    font-size: 50px;
  }
  
  .result-container1 {
    margin-top: 20px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    flex-wrap: wrap;
    border: 1px solid gray;
    /* background-color: rgba(211, 211, 211, 0.7); */
    background-color: transparent;
    border-radius: 10px;
    padding: 10px;
    max-width: 100%;
    width: 100%;
    overflow: hidden;
  }
  
  .result-container1 h3 {
    color: black;
  
  
    font-size: 30px;
  }
  
  .sample-image {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    align-items: center;
    height: 420px;
  }
  
  .sample {
    width: 300px;
    height: 190px;
    object-fit: fit;
    margin: 10px;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .sample:hover {
    transform: scale(1.05);
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.7);
  }
  
  .sample1 {
    display: flex;
  }
  
  .test-heading {
    background: var(--background1);
    background-color: #D1EAF0;
    margin-bottom: 20px;
    width: 98%;
    color: white;
    font-weight: bolder;
    font-size: 44px;
    text-align: center;
    padding: 20px;
    border-radius: 10px;
  
  }
  
h3 {
    text-align: left;
    font-size: 20px;
    color: orangered;
}

.image-upload-container {
    display: flex;
    margin-top: 10px;
    margin-bottom: 10px;
    padding: 15px;
    background-color: #e9f0fa;
    border: 1px solid rgb(189, 186, 186);
    justify-content: space-between;
    border-radius: 50px;
}

button {
    background-color: orangered;
    color: white;
    border: 1px solid;
    font-weight: 600;
    border-radius: 20px;
    padding: 10px;
    cursor: pointer;
    width: 150px;
    text-align: center;
    border-color: lightgray;
    margin: 5px;
}

.upload-icon {
    font-size: 30px;

}

.drop-container {
    margin-left: 10px;
}

.drop-zone {
    display: flex;
    align-items: center;
}

.result-container {
    margin-top: 20px;
    text-align: center;
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
    border-radius: 10px;
    padding: 20px;
    width: 100%;
}

.result {
    width: 100%;
    display: flex;
    justify-content: space-evenly;
}

.result-image {
    max-height: 400px;
    width: 500px;
    object-fit: fit;
    align-self: center;
    border-radius: 5px;
    margin-top: 10px;
}

.output {
    color: white;
}



.hr-line {
    width: 100%;
    border-top: 2px solid var(--blue6);
    margin: 10px 0;

}

.hr-line1 {
    width: 100%;
    border-top: 2px solid var(--blue6);
    margin: 10px 0;
    display: none;
}

.result-description,
.result-image,
.labels {
    transition: opacity 0.3s ease-in-out, transform 0.3s ease-in-out;
}

.result-image:hover {
    transform: scale(1.03);
}

.output-container {
    background-color: rgba(243, 242, 242, 0.7);
    /* background-color: white; */
    border: 1px dotted #333;
    border-radius: 10px;
    padding: 10px;
    box-shadow: 0 5px 10px rgba(0, 0, 0, 0.5);
    box-shadow: .75rem .75rem .2rem #4c77e336;
    display: flex;
    margin: 10px;
    height: 500px;
    width: 100%;
    flex-direction: column;

}


.output-content {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
    width: 100%;
}

.result-image {
    max-width: 580px;
    max-height: 350px;
    object-fit: cover;
    border-radius: 5px;
    margin-bottom: 10px;
    align-self: flex-start;
}

.vertical-line {
    width: 2px;
    height: 400px;
    background-color: #5e5d5d;
}

.labels {
    font-size: 18px;

    font-weight: bold;
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    margin-left: 30px;
}

.result-description {
    color: black;
    font-weight: bold;
    font-size: 25px;
    margin-left: 0%;

    align-self: center;
}

.result-out {
    width: 50%;
    align-self: center;
}

.result-out1 {
    width: 50%;
}

.label {
    line-height: 20px;
    margin-bottom: 5px;
    margin: 10px;
    font-size: 18px;

}

.paragraph {
    color: black;
    font-size: 13px;
}



.output-buttons button {
    padding: 10px;
    cursor: pointer;
    border: none;
    background-color: rgb(255, 247, 232);
    color: #333;
    width: 200px;
    font-size: 12px;
    transition: background-color 0.3s;
    border: 1px solid #333;
    border-color: whiteblack;
}


.output-buttons button.active {
    background-color: orangered;
    color: #fff;
    border: 1px solid orangered;
}


label.browse-button {
    background-color: orangered;
    color: white;
    border: 0;
    border-radius: 520px;
    padding: 10px;
    cursor: pointer;
    width: 110px;
    text-align: center;
    display: inline-block;
    margin-right: 10px;
    font-weight: bold;
    border-left: 1px solid;
    border-top: 1px solid;
    border-color: blackwhite;
}

.loader {
    width: 80px;
    height: 50px;
    position: relative;
    margin: auto;
  }
  
  .loader-text {
    position: absolute;
    top: 0;
    padding: 0;
    margin: 0;
    color: black;
    animation: text_713 3.5s ease both infinite;
    font-size: 1.8rem;
    letter-spacing: 1px;
    margin-top: 5px;
  }
  
  .load {
    background-color: #9A79FF;
    border-radius: 50px;
    display: block;
    height: 16px;
    width: 16px;
    bottom: 0;
    position: absolute;
    transform: translateX(64px);
    animation: loading_713 3.5s ease both infinite;
  }
  
  .load::before {
    position: absolute;
    content: "";
    width: 100%;
    height: 100%;
    background-color: #D1C2FF;
    border-radius: inherit;
    animation: loading2_713 3.5s ease both infinite;
  }
  
  @keyframes text_713 {
    0% {
      letter-spacing: 1px;
      transform: translateX(0px);
    }
  
    40% {
      letter-spacing: 2px;
      transform: translateX(26px);
    }
  
    80% {
      letter-spacing: 1px;
      transform: translateX(32px);
    }
  
    90% {
      letter-spacing: 2px;
      transform: translateX(0px);
    }
  
    100% {
      letter-spacing: 1px;
      transform: translateX(0px);
    }
  }
  
  @keyframes loading_713 {
    0% {
      width: 16px;
      transform: translateX(0px);
    }
  
    40% {
      width: 100%;
      transform: translateX(0px);
    }
  
    80% {
      width: 16px;
      transform: translateX(64px);
    }
  
    90% {
      width: 100%;
      transform: translateX(0px);
    }
  
    100% {
      width: 16px;
      transform: translateX(0px);
    }
  }
  
  @keyframes loading2_713 {
    0% {
      transform: translateX(0px);
      width: 16px;
    }
  
    40% {
      transform: translateX(0%);
      width: 80%;
    }
  
    80% {
      width: 100%;
      transform: translateX(0px);
    }
  
    90% {
      width: 80%;
      transform: translateX(15px);
    }
  
    100% {
      transform: translateX(0px);
      width: 16px;
    }
  }



  /* @media  (max-width:500px) {
    .sample{
      height: 125px;
      width: 260px;
    }
    
  } */









  @media (min-width:801px) and (max-width:1200px) {
    .input {
      width: 400px;
    }
  
    .result-image {
      width: 350px;
    }
  
    .sample {
      width: 250px;
      height: 190px;
    }
  
    .description {
      margin-left: 5px;
      font-size: 25px;
      line-height: 1.5;
    }
  
    .video-container {
      width: 500px;
    }
    .result-description{
     font-size: 15px;
     
     
     width: 100%;
    
    }
    .result-container1 {
     
      flex-direction: column;
      
      overflow: hidden;
    }
  }
  
  
  @media (min-width:501px) and (max-width: 800px) {
    .app {
      max-width: 99%;
  
    }
  
    .App {
      width: 100%;
    }
  
    .result-container1 {
      height: 800px;
      flex-direction: column;
      width: 700px;
      overflow: hidden;
    }
  
    .details {
      max-width: 100%;
    }
  
    .hr-line1 {
      display: block;
    }
  
    .vertical-line {
      display: none;
    }
  
    .result {
      flex-direction: column;
    }
  
    .output-content {
      flex-direction: column;
  
    }
  
    .output-container {
      flex-direction: column;
      height: 800px;
  
    }
  
    .result-out {
      height: 300px;
      display: flex;
      justify-content: center;
      align-items: center;
    }
  
    .step {
      width: 50%;
    }
  
    .head {
      flex-direction: column;
      align-items: center;
      gap: 1rem;
      padding: 1em;
    }
  
    .description {
      /* padding: 20px; */
      line-height: 25px;
      font-size: 20px;
      text-align: center;
      margin-left: 0%;
      margin-right: 0%;
    }
  
    .sample {
      width: 300px;
      height: 171px;
      margin: 5px;
    }
  
    button {
      padding: 8px;
    }
  
  
  
    .result-image {
      width: 380px;
      max-height: 350px;
      object-fit: cover;
      border-radius: 5px;
      margin-top: 10px;
    }
  
    
  
    .output-buttons button {
      padding: 5px;
      cursor: pointer;
      border: none;
      background-color: #f0f0f0;
      color: #333;
      font-size: 11px;
  
      width: 135px;
      transition: background-color 0.3s;
    }
  
    
  
    
    
  
   
  
  
   
  
    .output-image {
      width: 350px;
    }
  
    .result-image {
      width: 350px;
    }
  
    .input {
      height: 350px;
      width: 400px;
    }
  
    
    .description {
      line-height: 30px;
    }
  
    .test-heading {
      font-size: 30px;
    }
  
    .overcome {
      font-size: 20px;
    }
  
    .header h1 {
      font-size: 30px;
      line-height: 30px;
    }
  
    .test-heading-container p {
      font-size: 15px;
    }
  
    
  
    .test-heading-container1 h2 {
      font-size: 30px;
      margin: 0px;
    }
  }
  
  
  @media(max-width:500px) {
    
  
    .output-buttons button {
      width: 170px;
    }
  
    .output-image {
      width: 300px;
    }

    .result-image {
      width: 290px;
      height: 250px;
  
    }
  
    .input {
      width: 300px;
    }
  
    
  
    
  
    .output-container {
      background-color: rgba(211, 211, 211, 0.7);
      border: 1px solid #e0e0e0;
      border-radius: 10px;
      padding: 20px;
      box-shadow: 0 2px 5px rgba(0, 0, 0, 0.5);
      display: flex;
      width: 350px;
      height: 710px;
      flex-direction: column;
      flex-grow: 0 0 1;
    }
  
    .result-description {
      font-size: 10px;
      margin-top: -90px;
      width: 100%;
    }
  
    .result-container {
      padding: 10px;
      text-align: center;
    }
  
    .result-container1 {
      width: 320px;
      height: 710px;
      overflow: hidden;
    }
  
    .result-container1 h3 {
      text-align: center;
    }
  
    .sample {
      width: 250px;
      height: 135px;
      margin: 5px;
    }
  
    
  
   .browse-button {
    align-self: center;
   }
  
    .test-heading {
      font-size: 40px;
    }
  
    .description {
      line-height: 25px;
      font-size: 15px;
      padding: 0px;
      margin: 0px;
    }
  
    .steps1 {
      flex-direction: column;
      width: 100%;
      height: auto;
    }
  
   
  
   
  
    .steps1 {
      margin: 0px;
      margin-bottom: 10%;
    }
  
   
  
    .test-heading-container p {
      font-size: 13px;
    }
    .result-out{
      width: 100%;
      height: 270px;
    }
    .hr-line1{
      display: block;
    }
    
  }
  
  
  @media (max-width:400px) {
    .output-container {
      width: 98%;
    }
  
    .result-container1 {
      width: 100%;
      margin: 0px;
    }
  
    .result-container {
      padding: 0px;
    }
  
    .result-image {
      width: 240px;
    }
  
    .sample-image {
      max-width: 170px;
      height: 130px;
    }
  
    
  }
  
  